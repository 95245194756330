import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./ForgotPassword.styles.scss";
import { toast } from "react-toastify";
import { setStudent } from "../../../ReduxToolkit/Slices/studentSlice";
import forgetpassword from "../../../assets/HomeApp/images/forgetpassword.webp";
import { Welcome } from "../../Components/Welcome/Welcome";

import { Forgot_password_text } from "../../../assets/SharedApp/data/Auth";

import { useDispatch, useSelector } from "react-redux";
import {
  createPassword,
  forgotPass,
  handleUserChange,
  resendOTP,
  resetWithToken,
  verifyUser,
} from "../../../ReduxToolkit/Slices/userSlice";
import { validatePassword } from "../../../hooks";
import {
  loginUser,
  redirectLink,
  setUser,
} from "../../../ReduxToolkit/Slices/userSlice";
export default function ForgotPassword({ reset, otp, verify, setNew }) {
  return (
    <div className="reset__container">
      <Welcome
        text={
          reset
            ? "Reset Your Password"
            : otp
            ? "Please enter the OTP sent to your email address"
            : Forgot_password_text.welcome
        }
        img={forgetpassword}
      />

      <PasswordContainer
        reset={reset}
        otp={otp}
        verify={verify}
        setNew={setNew}
      />
    </div>
  );
}

const PasswordContainer = ({ reset, otp, verify, setNew }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const teacherEmail = useSelector(
    (state) => state?.teacher?.teacherToRegister?.email
  );

  const [agreed, setAgreed] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirm] = useState("");
  const [busy, setBusy] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  // const hashParams = !reset
  //   ? null
  //   : window.location.hash.substring(1).split("&");
  // const token = !hashParams ? null : hashParams[0].split("=")[1];
  // const userId = !hashParams ? null : hashParams[1].split("=")[1];

  const hashParams = !reset 
          ? null
          : window.location.search;
    const token = !hashParams ? null : new URLSearchParams(hashParams).get('token');
    const userId = !hashParams ? null : new URLSearchParams(hashParams).get('token2')


  const handleForgetState = (value) => {
    // dispatch(handleUserChange({ name: "forgotPassword", value }));
    setForgotPassword(value);
  };

  const navigate = useNavigate();
  const onOtpResend = () => {
    // continue here
    dispatch(
      resendOTP({
        email: teacherEmail ? teacherEmail : user.email,
      })
    ).then((res) => {
      if (typeof res.payload === "string") {
        return toast.error(res.payload);
      }
      if (typeof res?.payload?.data === "string") {
        return toast.success(res.payload.data);
      }
      toast.error("Error resending, please try again");
    });
  };

  const { userEmail, userOtp } = useParams();
  useEffect(() => {
    if (userOtp && userEmail && verify) {
      setEmail(userOtp);
    }
  }, [userOtp, userEmail, verify]);

  const onOtpSubmit = () => {
    if (!email) {
      return toast.error("Please enter your OTP");
    }
    dispatch(
      verifyUser({
        email: teacherEmail ? teacherEmail : userEmail ? userEmail : user.email,
        otp: email,
        recognized: agreed,
      })
    ).then((res) => {
      if (typeof res.payload === "string") {
        return toast.error(res.payload);
      }
      if (res?.payload?.data === "User verified") {
        toast.success("Thank you! Your email address is now verified");
        return navigate("/login");
      }
      toast.error("Error verifying, please try again");
    });
  };

  const onSubmit = () => {
    if (!reset) {
      if (!email) {
        return toast.error("Please enter your email address");
      }
      setBusy(true);
      dispatch(forgotPass({ email }))
        .then((Res) => {
          setBusy(false);
          if (Res.payload === "User not found") {
            return toast.error(
              "Please ensure that the email you have entered is correct"
            );
          }
          handleForgetState(true);
          toast.success(`A reset-password link was sent to ${email}`);
        })
        .catch((err) => {
          setBusy(false);
        });
    } else {
      if (!token) {
        return toast.error("Token expired, please try again.");
      }
      if (!validatePassword(password)) {
        return toast.error(
          "Password must be at least 8 characters long and contain at least one uppercase letter, one number, and one symbol (e.g. !@#$%^&*)"
        );
      }
      if (!password || !confirmPass) {
        return toast.error("Please fill out all inputs");
      }
      if (password !== confirmPass) {
        return toast.error("Passwords don't match");
      }
      setBusy(true);
      dispatch(
        resetWithToken({
          password,
          token,
          userId,
        })
      )
        .then((res) => {
          setBusy(false);
          if (res.payload === "Token expired") {
            return toast.error("Token has expired, please try again");
          }
          if (res.payload === "Token not found") {
            return toast.error(
              "There was an error with your link, please try again."
            );
          }
          if (res.type === "resetWithToken/fulfilled") {
            toast.success("Password reset!");
            return navigate("/login");
          }
        })
        .catch((err) => {
          setBusy(false);
        });
    }
  };

  const onCreatePasswordClick = () => {
    if (!validatePassword(password)) {
      return toast.error(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one number, and one symbol (e.g. !@#$%^&*)"
      );
    }
    if (!password || !confirmPass) {
      return toast.error("Please fill out all inputs");
    }
    if (password !== confirmPass) {
      return toast.error("Passwords don't match");
    }
    setBusy(true);

    dispatch(
      createPassword({
        otp: userOtp,
        email: userEmail,
        password,
        recognized: agreed,
      })
    ).then((Res) => {
      if (typeof Res.payload === "string") {
        return toast.error(Res.payload);
      }
      if (Res.type === "createPassword/fulfilled") {
        dispatch(setStudent(Res.payload.data.user.student.id));
        dispatch(setUser(Res.payload.data.user));
        dispatch(loginUser());
        toast.success("Welcome to Educify! Please confirm your booking :)");
        navigate("/students/admin-booking");
      }
    });

    setBusy(false);
  };
  return setNew ? (
    <div className="reset__password">
      <div className="inputs">
        <div>
          <h1>Create A Password</h1>
        </div>
        <p
          style={{
            fontSize: "14px",
            maxWidth: "80%",
            textAlign: "center",
            margin: "auto",
          }}
        >
          Before you start, kindly set a new password for your account
        </p>
        <div className="email">
          <h4 style={{ margin: "25px 0 10px 0" }}>Password</h4>
          <input
            type="password"
            name="password"
            value={password}
            placeholder={"Create A Password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <h4 style={{ margin: "25px 0 10px 0" }}>Confirm Password</h4>
          <input
            type="password"
            name="confirmPass"
            placeholder={"Confirm Password"}
            value={confirmPass}
            onChange={(e) => setConfirm(e.target.value)}
          />
          <div className="agreement">
            <input
              type="checkbox"
              onChange={(e) => setAgreed(!agreed)}
              value={agreed}
              checked={agreed}
            />
            <span>Mark this device as recognized for future logins</span>
          </div>
          <div
            onClick={() => onCreatePasswordClick()}
            className="signin__btn"
            style={{ width: "100% !important" }}
          >
            <button
              className={busy ? "fetching " : ""}
              style={{ margin: "25px 0", width: "100%" }}
            >
              Create Password
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="reset__password">
      <div className="inputs">
        <div>
          {reset ? (
            <h1>Reset Password</h1>
          ) : otp ? (
            <h1>Email Verification</h1>
          ) : setNew ? (
            <h1>Create A Password</h1>
          ) : (
            <h1>{Forgot_password_text.header}</h1>
          )}
          {!otp && (
            <p>
              <span>{Forgot_password_text.subHeader[0]}</span>
              <span className="blues">
                <Link to="/login"> {Forgot_password_text.subHeader[1]}</Link>
              </span>
            </p>
          )}
        </div>
        {!reset && !forgotPassword ? (
          <div className="email">
            {otp ? <h4>OTP</h4> : <h4>Email</h4>}
            <input
              type="text"
              name="email"
              value={email}
              placeholder={otp ? "Enter Your OTP" : "Enter Your Email Address"}
              onChange={(e) => setEmail(e.target.value)}
            />
            {otp && (
              <div className="agreement">
                <input
                  type="checkbox"
                  onChange={(e) => setAgreed(!agreed)}
                  value={agreed}
                  checked={agreed}
                />
                <span>Mark this device as recognized for future logins</span>
              </div>
            )}
          </div>
        ) : reset ? (
          <>
            <div className="email">
              <h4>New Password</h4>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="email">
              <h4>Confirm Password</h4>
              <input
                type="password"
                name="confimrPass"
                value={confirmPass}
                onChange={(e) => setConfirm(e.target.value)}
              />
            </div>
          </>
        ) : null}
        {otp ? (
          <>
            <div onClick={() => onOtpSubmit()} className="signin__btn">
              <button className={busy ? "fetching " : ""}>Verify</button>
            </div>
            <span className="resend">
              <span>Didn't Receive An OTP?</span>
              {
                <span
                  className={busy ? "fetching blues pointer" : "blues pointer"}
                  onClick={() => onOtpResend()}
                >
                  {" "}
                  {Forgot_password_text.resend[1]}
                </span>
              }
            </span>
          </>
        ) : reset || (!reset && !forgotPassword) ? (
          <div onClick={() => onSubmit()} className="signin__btn">
            <button className={busy ? "fetching " : ""}>
              {Forgot_password_text.button}
            </button>
          </div>
        ) : (
          <span className="resend">
            <span>{Forgot_password_text.resend[0]}</span>
            {
              <span
                className={busy ? "fetching blues pointer" : "blues pointer"}
                onClick={() => onSubmit()}
              >
                {" "}
                {Forgot_password_text.resend[1]}
              </span>
            }
          </span>
        )}
      </div>
      {/* <div className="terms">
        <span>{terms.terms}</span>
        <span>{terms.Plans}</span>
        <span>{terms.ContactUs}</span>
      </div> */}
    </div>
  );
};
