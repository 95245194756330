import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import forgetpassword from "../../../assets/HomeApp/images/forgetpassword.webp";
import { Welcome } from "../../Components/Welcome/Welcome";
import { Forgot_password_text } from "../../../assets/SharedApp/data/Auth";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  loginWithLink,
  redirectLink,
  setUser,
} from "../../../ReduxToolkit/Slices/userSlice";
import { setStudent } from "../../../ReduxToolkit/Slices/studentSlice";
import {
  logout,
  setAuthToken,
} from "../../../ReduxToolkit/customFetch/customFetch";
import { getTeacherById } from "../../../ReduxToolkit/Slices/teacherSlice";
import Loading from "./LoadingScreen";

export default function AuthWithLink() {
  return (
    <div className="reset__container">
      <Welcome
        text={
          "Authentication process going on..."
        }
        img={forgetpassword}
      />

      <AuthenticationContainer reset={reset} otp={otp} />
    </div>
  );
}

const AuthenticationContainer = () => {
  const [authLink, authLinkLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
//   const { user } = useSelector((state) => state.user);
  const redirect = useSelector((state) => state.user.redirectLink);

  // Fetch tokens from URL and trigger login
   useEffect(() => {
     const queryParams = new URLSearchParams(window.location.search);
     const token1 = queryParams.get("token1");
     const token2 = queryParams.get("token2");
 
     if (token1 && token2) {
        loginDispatch(token1, token2);
     }
   }, []); // Run on component mount

  const logoutUser = () => {
    localStorage.clear();
    setAuthToken("delete");
  };

  const handleSignOut = () => {
    logoutUser();
    dispatch(logout());
    navigate("/");
  };


const loginDispatch = (token1, token2) => {
     authLinkLoading(true)
  dispatch(loginWithLink({ token1, token2 }))
    .then((res) => {
      const { payload, type } = res;
      const message = payload?.data?.message;
      const user = payload?.data?.user;

      // Handle various error messages first
      if (typeof message === "string") {
        toast.error(message);
         return navigate("/login");
     
      }
      if (payload.data === "Please authenticate") {
        dispatch(setUser(user));
        toast.error("Please enter the code that you've received by mail");
        return navigate("/authenticate");
      }
      if (payload.data === "Please verify your email address before logging in.") {
        dispatch(setUser(user));
         authLinkLoading(false)
        toast.error(payload.data);
        return navigate("/otp");
      }
      if (message === "Your account has been temporarly blocked") {
         authLinkLoading(false)
         toast.error(message);
        return navigate("/login");
      }
      if (message === "User is not verified") {
        dispatch(setUser({ email: user?.email }));
         authLinkLoading(false)
        toast.error("Your email address is not verified yet");
        return navigate("/otp");
      }

      // Handle rejected login
      if (type.includes("reject")) {
         authLinkLoading(false)
         toast.error("Invalid credentials");
        return navigate("/login");
      }

      // Handle fulfilled login
      if (type === "login/fulfilled") {
        const role = res.payload?.user?.role;

        dispatch(setUser(res.payload.user));
        dispatch(loginUser());

        switch (role) {
          case "ADMIN":
            authLinkLoading(false)
            return navigate("/admin", { replace: true });

          case "STUDENT":
            dispatch(setStudent(res.payload.user.student.id));
            if (redirect) {
              dispatch(redirectLink(""));
              authLinkLoading(false)
              return navigate(redirect, { replace: true });
            }
             authLinkLoading(false)
            return navigate("/students", { replace: true });

          case "TEACHER":
            if (!res.payload.user.teacher?.id) {
              handleSignOut();
              authLinkLoading(false)
               toast.error("Your account has been suspended");
               return navigate("/login");
            }

            dispatch(getTeacherById(res.payload.user.teacher?.id))
              .then(() => navigate("/teachers", { replace: true }))
              .catch(() => {
                authLinkLoading(false)
                toast.error("Failed to fetch teacher details")
                 return navigate("/login");
              }
                );
               authLinkLoading(false)
            break;

          default:
             authLinkLoading(false)
            toast.error("Unknown user role");
             navigate("/login");
            break;
        }
      }
    })
    .catch((err) => {
      console.error("Error logging in:", err);
       authLinkLoading(false)
      toast.error("An unexpected error occurred. Please try again.");
       navigate("/login");
    });
};

  return (
    <div className="reset__password">
        <div>
          <h1>Signing you in...</h1>
            <p>
              <span>Sit back and take a coffee </span>
              <span className="blues">
                ☕️
              </span>
            </p>

        </div>
        {authLink && (<Loading/>)}
   

    </div>
  );
};
