import React, { useLayoutEffect, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./GlobalStyles/app.styles.scss";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

// import LoadingAnimation from "./SharedApp/Pages/LoadingAnimation/LoadingAnimation"
import Landing from "./Sections/Landing/Landing";
import ForgotPassword from "./SharedApp/Pages/ForgotPassword/ForgotPassword";
import Login from "./SharedApp/Pages/Login/Login";
import Signup from "./SharedApp/Pages/Signup/Signup";
import PasswordChanged from "./SharedApp/Pages/PasswordChanged/PasswordChanged";
import HomePage from "./HomeApp/HomePage/HomePage";
import Tutors from "./Sections/Tutors/Tutors";
import TutorPage from "./SharedApp/Pages/TutorPage/TutorPage";
import Authenticate from "./SharedApp/Pages/Authenticate/Authenticate";
// import TutorMedia from "./SharedApp/Pages/TutorMedia/TutorMedia";
import Packages from "./SharedApp/Pages/Packages/Packages";
import PaymentSuccess from "./StudentApp/Pages/PaymentSuccess/PaymentSuccess";
import PaymentMethod from "./StudentApp/Pages/PaymentMethod/PaymentMethod";
import TutorSignup from "./TeacherApp/Pages/TutorSignup/TutorSignup";
import SignupDetails from "./TeacherApp/Pages/SignupDetails/SignupDetails";
import StudentApp from "./StudentApp/StudentApp";

import NavFooterShared, {
  NavFooterSharedCourses,
} from "./Components/NavFooterShared/NavFooterShared";
import TeacherApp from "./TeacherApp/TeacherApp";
import Favorites from "./StudentApp/Pages/Favorites/Favorites";
// import LessonPackage from "./SharedApp/Pages/LessonPackage/LessonPackage";
import SetupProfile from "./TeacherApp/Pages/SetupProfile/SetupProfile";
import SetupLesson from "./TeacherApp/Pages/SetupLesson/SetupLesson";
import Review from "./TeacherApp/Pages/Review/Review";
import Complete from "./TeacherApp/Pages/Complete/Complete";
import Schedule from "./TeacherApp/Pages/Schedule/Schedule";
import NotFound, {
  ServerOffline,
  UserOffline,
} from "./SharedApp/Pages/404/NotFound";
import AdminApp from "./AdminApp/AdminApp";
import Dates from "./StudentApp/Pages/Date/Date";
import MeetingRoomContainer from "./MeetingRoomContainer";
import { useDispatch, useSelector } from "react-redux";
// import Notifications from "./SharedApp/Pages/Courses/Notifications";
import Notifications from "./StudentApp/Pages/Courses/Notifications/Notifications";
import Overview from "./StudentApp/Pages/Courses/overview/Overview";
import { loginUser, register, setUser } from "./ReduxToolkit/Slices/userSlice";

import InstructorProfile from "./SharedApp/Pages/Courses/InstructorProfile/InstructorProfile";
// import GiftCourse from "./SharedApp/Pages/Courses/GiftCourse/GiftCourse";
import Cart from "./SharedApp/Pages/Courses/Cart/Cart";
import CategoryPage from "./SharedApp/Pages/Courses/CategoryPage/CategoryPage";
import MyLearning from "./SharedApp/Pages/Courses/MyLearning/MyLearning";
import CoursesHomePage from "./SharedApp/Pages/Courses/CoursesHomePage/CoursesHomePage";
import GroupLessonsPage from "./SharedApp/Pages/GroupLessonsPage/GroupLessonsPage";
import StartCourse from "./SharedApp/Pages/Courses/StartCourse/StartCourse";
import {
  getAvandedCart,
  setStudent,
  setStudentCart,
} from "./ReduxToolkit/Slices/studentSlice";
import AddCourse from "./TeacherApp/Pages/AddCourse/AddCourse";
import NewOnlineCourse from "./TeacherApp/Pages/Courses/NewOnlineCourse/NewOnlineCourse";
import AddCurriculum from "./TeacherApp/Pages/Courses/AddCurriculum/AddCurriculum";
import Privacy from "./SharedApp/Pages/Content/Privacy";
import TOS from "./SharedApp/Pages/Content/TermsOfServices";
import HowItWorks from "./SharedApp/Pages/Content/HowItWorks";
import Educify from "./SharedApp/Pages/Content/Educify";
import PackagesCombos from "./SharedApp/Pages/Content/PackagesCombos";
import BgCheck from "./SharedApp/Pages/Content/BgCheck";
import FaqList from "./SharedApp/Pages/Content/Faq";
import DataDelete from "./SharedApp/Pages/Content/DataDelete";
import AuthWithLink from "./SharedApp/Pages/AuthWithLink/AuthLinkPage";
// import Test from "./Test";
import GroupLesson from "./SharedApp/Pages/GroupLesson/GroupLesson";
import RecommendTeacher from "./SharedApp/Pages/RecommendTeacher/RecommendTeacher";

// import eximg1 from "./assets/AdminApp/images/eximg1.webp";
// import eximg2 from "./assets/AdminApp/images/eximg2.webp";
// import eximg3 from "./assets/AdminApp/images/eximg3.webp";
// import eximg4 from "./assets/AdminApp/images/eximg4.webp";
import Copyright from "./SharedApp/Pages/Content/Copyright";
import SignUpContainer from "./TeacherApp/Pages/SignUpContainer/SignUpContainer";
import SharedBlogs from "./SharedApp/Pages/SharedBlogs/SharedBlogs";
import ViewSharedBlog from "./SharedApp/Pages/ViewSharedBlog/ViewSharedBlog";
import TeacherLanding from "./TeacherApp/Pages/TeacherLanding/TeacherLanding";
import ChatBot from './ChatBot';

// Sentry.init({
//   dsn: "https://d0133486d44ef784a7cbfec319ed461f@o4507408919953408.ingest.us.sentry.io/4507448395628544",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [
//     "localhost",
//     "https://educify.org/",
//     "https://educify.org",
//     "https://www.educify.org",
//     "https://www.educify.org/",
//     "educify.org",
//   ],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

function AppContainer({ signedIn }) {
  const dispatch = useDispatch();
  const user1 = useSelector((state) => state.user.user);
  const { student } = useSelector((state) => state.student);
  const { showCourses } = useSelector((state) => state.user);
  const location = useLocation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { cartItemsRefresh } = useSelector((state) => state.student);

  useEffect(() => {
    user1.role === "STUDENT" &&
      dispatch(getAvandedCart()).then((res) => {
        if (res.type.includes("fulfilled")) {
          dispatch(setStudentCart(res?.payload?.data));
        } else {
          dispatch(setStudentCart([]));
        }
      });
  }, [cartItemsRefresh]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");

    let storageUser = localStorage.getItem("user");
    let loggedIn = localStorage.getItem("loggedIn");
    if (loggedIn && storageUser) {
      dispatch(setUser(JSON.parse(storageUser)));
      dispatch(loginUser());
    }
    if (loggedIn && JSON.parse(storageUser).role === "STUDENT") {
      let studentID = localStorage.getItem("studentId");
      if (studentID) {
        dispatch(setStudent(JSON.parse(studentID)));
      }
    }
  }, []);
  let key = import.meta.env.VITE_STRIPE_KEY;
  const stripePromise = loadStripe(key);

  const user = {
    name: "Admin",
    email: "admin@admin.admin",
    lastName: "Admin",
    password: "admin1234",
    nationality: "lebanese",
    role: "ADMIN",
    bySocial: false,
    phone: "",
    gender: "MALE",
    profileImage: {
      publicId: "",
      url: "",
    },
    address: {
      street: "street",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },
  };

  const registerAdmin = () => {
    dispatch(register(user));
  };
  // moment.tz.setDefault("UTC");
  return (
    <Elements stripe={stripePromise}>
      {!isOnline && (
        <div className="flex_center offline">
          <p>You are not connected to the internet</p>
        </div>
      )}
      {/* <button onClick={() => registerAdmin()}>Register admin</button> */}
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/server-offline" element={<ServerOffline />} />
        <Route path="/user-offline" element={<UserOffline />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/animate" element={<LoadingAnimation />} /> */}
        {/* <Route path="/test" element={<Test />} /> */}
        <Route path="/login/sessionExpired" element={<Login expired />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/teacher" element={<TeacherLanding />} />
        <Route path="/teacher-signup/*" element={<SignUpContainer />} />
        <Route path="/forget" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ForgotPassword reset />} />
        <Route path="/otp" element={<ForgotPassword otp />} />
        <Route
          path="/verify/:userEmail/:userOtp"
          element={<ForgotPassword verify={true} otp />}
        />
        <Route
          path="/set-new-password/:userEmail/:userOtp"
          element={<ForgotPassword setNew />}
        />
        <Route path="/authenticate" element={<Authenticate />} />

        <Route path="/signin-link-authenticate" element={<AuthWithLink />} />

        <Route path="/teacher/signup/profile" element={<SetupProfile />} />
        <Route
          path="/teacher/signup/Course"
          element={
            <AddCourse step={4}>
              <NewOnlineCourse />
            </AddCourse>
          }
        />
        <Route
          path="/teacher/signup/chapters"
          element={
            <AddCourse step={4}>
              <AddCurriculum />
            </AddCourse>
          }
        />
        <Route
          path="/teacher/signup/availability"
          element={<Schedule customLinks={false} />}
        />
        <Route path="/teacher/signup/lessons" element={<SetupLesson track />} />
        <Route path="/teacher/signup/submit" element={<Review />} />
        <Route path="/teacher/signup/completed" element={<Complete />} />
        <Route
          path="/lessonRoom/:teacherId/:userId/:lesson"
          element={<MeetingRoomContainer />}
        />
        <Route
          path="/admin/*"
          element={
            // <ProtectedRoute role="ADMIN">
            <AdminApp />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/blogadmin/*"
          element={
            // <ProtectedRoute role="ADMIN">
            <AdminApp />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/teachers/*"
          element={
            // <ProtectedRoute role="TEACHER">
            <TeacherApp />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/students/*"
          element={
            // <ProtectedRoute role="STUDENT">
            <StudentApp />
            // </ProtectedRoute>
          }
        />
        <Route path="" element={<NavFooterShared />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/landing/:category/" element={<Landing />} />
          <Route path="/success" element={<PasswordChanged />} />
          <Route path="/grouplessons" element={<GroupLessonsPage />} />
          <Route path="/grouplessons/:id" element={<GroupLesson />} />
          <Route path="/tutors/search/:search" element={<Tutors search />} />
          <Route
            path="/tutors/location/:location"
            element={<Tutors locations search />}
          />
          <Route
            path="/tutors/search/:subject/:category"
            element={<Tutors subjectSearch />}
          />
          <Route path="/tutors/" element={<Tutors noFilter />} />
          <Route path="/tutors/:lesson/" element={<Tutors />} />
          <Route path="/tutor/:id" element={<TutorPage />} />
          <Route
            path="/tutor/:id/verify"
            element={<Complete verifyAccount />}
          />
          <Route path="/tutor/:id/:subject" element={<TutorPage />} />
          {/* <Route path="/tutor/:id/media" element={<TutorMedia />} /> */}
          <Route path="/packages/:id" element={<Packages />} />
          <Route path="/payment" element={<PaymentSuccess />} />
          <Route path="/method" element={<PaymentMethod />} />
          <Route
            path="/admin-booking/method/:studentId/:bookingId"
            element={<PaymentMethod isAdminBooking />}
          />
          <Route path="/method/:appointmentId" element={<PaymentMethod />} />
          <Route path="/date" element={<Dates />} />
          <Route path="/date/:bookingId" element={<Dates />} />
          <Route path="/favorites" element={<Favorites />} />
          {/* courses */}

          {/* <Route path="/courses/gift" element={<GiftCourse />} /> */}
          {/* content */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/copyright" element={<Copyright />} />
          <Route path="/data" element={<DataDelete />} />
          <Route path="/terms" element={<TOS />} />
          <Route path="/cancelation" element={<TOS cancelation={true} />} />
          <Route path="/online-lessons" element={<TOS cancelation={true} />} />
          <Route path="/terms/students" element={<TOS isStudents={true} />} />
          <Route path="/terms/teachers" element={<TOS isTeachers={true} />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/about-educify" element={<Educify />} />
          <Route path="/Packages&Combos" element={<PackagesCombos />} />
          <Route path="/bg-check" element={<BgCheck />} />
          <Route path="/faq" element={<FaqList />} />
          {/* blogs */}
          <Route path="/blogs" element={<SharedBlogs />} />
          <Route path="/blogs/:id" element={<ViewSharedBlog />} />

          {/*  */}
        </Route>
        <Route path="" element={<NavFooterSharedCourses />}>
          <Route path="/cart" element={<Cart />} />
          <Route path="/recommend/:id" element={<RecommendTeacher />} />

          {/* courses */}
          {showCourses && (
            <>
              <Route path="/courses" element={<CoursesHomePage />} />
              <Route path="/courses/cart" element={<Cart />} />
              <Route path="/courses/:id" element={<Overview />} />
              <Route
                path="/courses/instructor/:id"
                element={<InstructorProfile />}
              />
              {/* <Route path="/courses/gift" element={<GiftCourse />} /> */}

              <Route
                path="/courses/categories/:category"
                element={<CategoryPage />}
              />
              <Route
                path="/courses/search/:search"
                element={<CategoryPage searching />}
              />
              <Route path="/courses/all/" element={<CategoryPage all />} />
              <Route path="/courses/:id/buy" element={<PaymentMethod />} />
              <Route path="/courses/mylearning" element={<MyLearning />} />

              <Route path="/courses/:id/start" element={<StartCourse />} />
              {/* content */}
            </>
          )}
        </Route>
        
      </Routes>
      <ChatBot />
    </Elements>
  );
}

export default AppContainer;
