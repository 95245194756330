import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { teacherDeleteNotes } from "../../../ReduxToolkit/Slices/lessonsSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getStudentSubscribedLessons,
  unsubscribeStudentLesson,
} from "../../../ReduxToolkit/Slices/studentSlice";
const StudentsSubscriptions = () => {
  const dispatch = useDispatch();
  // const { student } = useSelector((state) => state.student);
  const [bookings, setBookings] = useState([]);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    dispatch(getStudentSubscribedLessons()).then((res) => {
      if (res.type.includes("fulfilled")) {
        setBookings(res.payload.lessons);
      } else {
        setBookings([]);
      }
    });
  }, [refresh]);

  return (
    <div className={`manage__students homeworks_table sub_table`}>
      <div className="header">
        <div className="header__text">
          <span>Subscriptions</span>
        </div>
      </div>
      <div className="students__table">
        <table>
          <thead>
            <tr>
              <th style={{ fontSize: "1.1rem" }}>Title</th>
              <th style={{ fontSize: "1.1rem" }}>Teacher</th>
              {/* <th>Submitted</th> */}
              <th style={{ fontSize: "1.1rem" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {bookings?.length > 0 ? (
              bookings.map((row, i) => (
                <StudentsNotesRow
                  row={row}
                  key={i}
                  i={i}
                  setRefresh={setRefresh}
                  refresh={refresh}
                />
              ))
            ) : (
              <p>You are not subscribed to any lessons!</p>
            )}
          </tbody>
        </table>
      </div>
      <div style={{
        padding: "10px",
        backgroundColor: "#f8d7da",
        color: "#721c24",
        border: "1px solid #f5c6cb",
        borderRadius: "5px",
        fontWeight: "bold",
        fontSize: "0.7rem",  // Smaller text
        fontStyle: "italic", // Italic text
        marginBottom: "20px",
        textAlign: "center"
      }}>
        To unsubscribe the subscription, kindly send an email to <a href="mailto:support@educify.org" style={{ color: "#721c24", textDecoration: "underline" }}>support@educify.org</a>
      </div>
    </div>
  );
};

const StudentsNotesRow = ({ row, i, setRefresh, refresh, className }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleUnsubscribe = (id) => {
    dispatch(unsubscribeStudentLesson(id)).then((res) => {
      if (res.type.includes("fulfilled")) {
        toast.success("Unsubscribed Successfully from lesson!");
        setRefresh(!refresh);
      } else toast.error(res.payload);
    });
  };
  return (
    <tr key={i}>
      <td>
        <span>{row?.lesson?.title}</span>
      </td>
      <td>
        <span>{row?.lesson?.teacher?.user?.name}</span>
      </td>
      <td style={{ maxWidth: "300px" }}>
        <div className="gap">
          <button
            className="accepted"
            onClick={() => {
              navigate(`/tutors/search/${row?.lesson?.title}`);
            }}
          >
            View Lesson
          </button>
          {/*<button
            className="accepted"
            onClick={() => {
              let confirm = window.confirm(
                "Are you sure you want to unsubscribe from this lesson?"
              );
              if (confirm) {
                handleUnsubscribe(row.id);
              }
            }}
          >
            Unsubscribe
          </button>*/}
        </div>
      </td>
    </tr>
  );
};
export default StudentsSubscriptions;
