import React, { useState, useEffect } from "react";
import "./Login.styles.scss";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Welcome } from "../../Components/Welcome/Welcome";
import { Link } from "react-router-dom";

import { terms } from "../../../assets/SharedApp/data/Auth";
import { Login_text } from "../../../assets/SharedApp/data/Auth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  loginUser,
  logout,
  redirectLink,
  resetUserState,
  setUser,
} from "../../../ReduxToolkit/Slices/userSlice";
import { toast } from "react-toastify";
import {
  getTeacherById,
  resetTeacherState,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import {
  getStudentById,
  resetStudentState,
  setStudent,
} from "../../../ReduxToolkit/Slices/studentSlice";

import login_bg from "../../../assets/HomeApp/images/login_bg.webp";
import google from "../../../assets/HomeApp/svgs/googe-icon-white.svg";

import facebook from "../../../assets/HomeApp/svgs/facebook.svg";
import stripeIcon from "../.../../../../assets/payment-icons/stripe.svg";
import masterCardIcon from "../.../../../../assets/payment-icons/mastercard.svg";
import paypal from "../.../../../../assets/payment-icons/paypal.svg";
import visa from "../.../../../../assets/payment-icons/visa.svg";
import showPassword from "../../../assets/SharedApp/svgs/show.svg";
import hidePassword from "../../../assets/SharedApp/svgs/hide.svg";
import axios from "axios";

import { setAuthToken } from "../../../ReduxToolkit/customFetch/customFetch";

export default function Login({ expired }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Check for expired session and reset state if expired
  useEffect(() => {
    if (expired) {
      alert("Session is timed out, please log in again.");
      dispatch(resetUserState());
    }
  }, [expired, dispatch]);


  return (
    <div className="login__container">
      <Welcome text={"Welcome to Educify"} img={login_bg} />
      <LoginContainer />
    </div>
  );
}

const LoginContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentImages = [paypal, visa, masterCardIcon, stripeIcon];
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [passType, setPassType] = useState(false);
  const sendToast = (text) => toast.error(text);
  const [showPasswordLogin, setShowPasswordLogin] = useState(false); // State to toggle the dropdown

  const redirect = useSelector((state) => state.user.redirectLink);

  const user = { email: email, password: password };
  const [showEmailPopup, setShowEmailPopup] = useState(false); // Email popup state
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const auth = firebase.auth();
  const [isSendingLink, setIsSendingLink] = useState(false); // State for "Send me a sign-in link"
  const base_url = import.meta.env.VITE_BASEURL;
  const sendSignInLink = async () => {
    if (!email) {
      return toast.warning("Please enter your email to receive the sign-in link.");
    }

    setIsSendingLink(true);

    try {
      // Call the backend API to send the sign-in link
      const response = await axios.post(`${base_url}/api/v1/users/email/signin-link`, { email });

      if (response.status === 200) {
        setShowEmailPopup(false)
        toast.success("A sign-in link has been sent to your email.");
      } else {
        toast.error("Failed to send sign-in link. Please try again.");
      }
    } catch (err) {
      toast.error("An error occurred. Please check your email and try again.");
    } finally {
      setIsSendingLink(false);
    }
  };
  const loginWithFacebook = async () => {
    try {
      const res = await firebase
        .auth()
        .signInWithPopup(new firebase.auth.FacebookAuthProvider());

      const user = res.user;
      const firebaseToken = await user.getIdToken();
      const facebookUser = {
        email: res.additionalUserInfo.profile.email,
        firebaseToken,
        bySocial: true,
      };

      dispatch(login(facebookUser)).then((res) => {
        if (typeof res?.payload?.data === "string") {
          let message = res?.payload?.data;
          if (message === "Invalid credentials") {
            toast.error("Kindly create an account before logging in");
            return navigate("/signup");
          } else return toast.error(message);
        }

        if (res.type.includes("fulfilled")) {
          handleLoginSuccess(res);
        }
      });
    } catch (error) {
      if (error.message === "Invalid credentials") {
        toast.error("Kindly create an account before logging in");
        return navigate("/signup");
      } else return toast.error(error.message);
    }
  };

  useEffect(() => {
    const credentials = JSON.parse(localStorage.getItem("credentials"));
    if (credentials?.email && credentials?.password) {
      setEmail(credentials.email);
      setPassword(credentials.password);
    }
  }, []);

  

  const logoutUser = () => {
    localStorage.clear();
    setAuthToken("delete");
  };

  const handleSignOut = () => {
    logoutUser();
    dispatch(logout());
    navigate("/");
  };

  // handleLoginSuccess function
  const handleLoginSuccess = async (res) => {
    if (res.type === "login/fulfilled") {
      // Call loginWithSignInLink if tokens are present in the response
      if (res.payload.token1 && res.payload.token2) {
        await loginWithSignInLink(res.payload.token1, res.payload.token2);
        return; // Exit early if loginWithSignInLink was called
      }

      // Continue with the normal login flow
      if (res.payload.user.role === "ADMIN") {
        dispatch(loginUser());
        dispatch(setUser(res.payload.user));
        setBusy(false);
        return navigate("/admin", { replace: true });
      }

      if (res.payload.user.role === "BLOGADMIN") {
        dispatch(loginUser());
        dispatch(setUser(res.payload.user));
        setBusy(false);
        return navigate("/blogadmin", { replace: true });
      }

      if (res.payload.user.role === "STUDENT") {
        dispatch(setStudent(res.payload.user.student.id));
        dispatch(setUser(res.payload.user));
        dispatch(loginUser());
        if (redirect) {
          dispatch(redirectLink(""));
          setBusy(false);
          return navigate(redirect, { replace: true });
        } else {
          navigate("/students", { replace: true });
        }
      } else if (res.payload.user.role === "TEACHER") {
        if (!res.payload.user.teacher?.id) {
          handleSignOut();
          return sendToast("Your account has been suspended");
        }
        dispatch(setUser(res.payload.user));
        dispatch(loginUser());

        dispatch(getTeacherById(res.payload.user.teacher?.id))
          .then((res) => {
            setBusy(false);
            navigate("/teachers", { replace: true });
          })
          .catch((err) => { });
      }
    }
  };

  const handleLoginResponse = (res, user) => {
    let users = user ? user : res.payload.user;
    if (res.payload.data === "Please authenticate") {
      dispatch(setUser(users));
      toast.success("Please enter the code that you've received by mail");
      return navigate("/authenticate");
    }
    if (
      res.payload.data === "Please verify your email address before logging in."
    ) {
      toast.error(res.payload.data);
      setBusy(false);
      dispatch(setUser(users));
      return navigate("/otp");
    }
    if (
      res?.payload?.data?.message === "Your account has been temporarly blocked"
    ) {
      setBusy(false);
      return toast.error("Your account has been temporarly blocked");
    }
    if (res?.payload?.data?.message === "User is not verified") {
      toast.error("Your email address is not verified yet");
      dispatch(setUser({ email: users.email }));
      setBusy(false);
      return navigate("/otp");
    }
    if (res.type.includes("reject")) {
      setBusy(false);
      if (typeof res?.payload?.data === "string") {
        return toast.error(res.payload.data);
      }
      return toast.error("Invalid credentials");
    }
    if (res.type.includes("fulfilled")) {
      handleLoginSuccess(res);
    }
  };
  const loginDispatch = (users) => {
    setError("");
    setBusy(true);
    dispatch(login(users)).then((res) => {
      handleLoginResponse(res, users);
      //  else sendToast(res.payload);
    });
  };
  const loginWithGoogle = async () => {
    try {
      const res = await auth.signInWithPopup(googleProvider);
      const user = res.user;
      const firebaseToken = await user.getIdToken();
      const googleUser = {
        email: res.additionalUserInfo.profile.email,
        firebaseToken,
        bySocial: true,
      };

      dispatch(login(googleUser)).then((res) => {
        if (typeof res?.payload?.data === "string") {
          let message = res?.payload?.data;
          if (message === "Invalid credentials") {
            toast.error("Kindly create an account before logging in");
            return navigate("/signup");
          } else return toast.error(message);
        }

        if (res.type.includes("fulfilled")) {
          handleLoginSuccess(res);
        }
      });
    } catch (error) {
      if (error.message === "Invalid credentials") {
        toast.error("Kindly create an account before logging in");
        return navigate("/signup");
      } else return toast.error(error.message);
    }
  };
  const handleLogin = async () => {
    if (!email && !password) {
      toast.warning("You need to enter a valid email and password");
    } else if (!email) {
      toast.warning("You need to enter a valid email");
    } else if (!password) {
      toast.warning("You need to enter a valid password");
    } else if (email && password) {
      loginDispatch(user);
    }
  };


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="inner__login">
      <h1>{Login_text.header}</h1>
      <p>
        {Login_text.subHeader[0]}
        <span>
          <Link style={{ color: "inherit" }} to={"/signup"}>
            {" "}
            {Login_text.subHeader[1]}
          </Link>
        </span>
      </p>

      {/* Log in with Password Button */}
      <button
        style={{
          backgroundColor: "white",
          color: "black",
          width: "54%",
          height: "50px",
          padding: "10px 15px",
          fontWeight: "bold",
          fontSize: "18px",
          border: "1px solid black",
          borderRadius: "5px",
          cursor: "pointer",
          marginBottom: "30px",
          marginTop: "15px",
        }}
        onClick={() => setShowPasswordLogin(true)} // Show modal on click
      >
        Sign in with Password
      </button>

      {/* Popup Modal */}
      {showPasswordLogin && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
          }}
        >
          <div className="inputs" onSubmit={() => handleLogin()}>
            <div className="email">
              <h4>{Login_text.email}</h4>
              <input
                type="text"
                name="email"
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
                value={email}
              />
            </div>
            <div className="password">
              <div className="header">
                <h4>{Login_text.password}</h4>
                <span>
                  <Link to="/forget">{Login_text.forgot_password}</Link>
                </span>
              </div>
              <div className="input_with_icon">
                <input
                  type={passType ? "text" : "password"}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  value={password}
                />
                <img
                  src={passType ? showPassword : hidePassword}
                  alt=""
                  onClick={() => setPassType(!passType)}
                />
              </div>
            </div>
            <button
              className={busy ? "signin__btn fetching" : "signin__btn"}
              to="/students"
              type="submit"
              onClick={() => handleLogin()}
            >
              {Login_text.button}
            </button>
          </div>

          {/* Close Button */}
          <button
            style={{
              marginTop: "10px",
              backgroundColor: "red",
              color: "white",
              padding: "5px 10px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => setShowPasswordLogin(false)}
          >
            Close
          </button>
        </div>
      )}

      {/* Overlay */}
      {showPasswordLogin && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={() => setShowPasswordLogin(false)} // Close modal on overlay click
        ></div>
      )}

      {/* Send me a sign-in link */}
      <button
        style={{
          backgroundColor: "black",
          color: "white",
          width: "54%",
          height: "50px",
          padding: "10px 15px",
          fontWeight: "bold",
          fontSize: "18px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          marginTop: "10px",
          marginBottom: "50px",
        }}
        onClick={() => setShowEmailPopup(true)} // Show email popup
        disabled={isSendingLink}
      >
        Send me a sign-in link
      </button>
      {/* Email Popup */}
      {showEmailPopup && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
          }}
        >
          <h3>Enter your email to receive a sign-in link</h3>
          <input
            type="text"
            placeholder="Enter your email"
            style={{
              width: "45%",
              padding: "10px",
              marginBottom: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginRight: "10px", // Add space to the right
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
          />
          <button
            style={{
              backgroundColor: "#28a745",
              color: "white",
              padding: "10px 15px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginRight: "10px", // Add space to the right
            }}
            onClick={() => sendSignInLink(email)}
          >
            {isSendingLink ? "Sending..." : "Send Link"}
          </button>
          <button
            style={{
              marginTop: "10px",
              backgroundColor: "red",
              color: "white",
              padding: "10px 15px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              
            }}
            onClick={() => setShowEmailPopup(false)}
          >
            Cancel
          </button>
        </div>
      )}
      {/* Overlay for Email Popup */}
      {showEmailPopup && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={() => setShowEmailPopup(false)} // Close modal on overlay click
        ></div>
      )}
      <div
        className="signin__with"
        onClick={() => loginWithGoogle()}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <span
          style={{
            fontSize: "18px", // Adjust font size
            lineHeight: "20px", // Match the logo height
            display: "inline-flex", // Align items inline
            alignItems: "center", // Vertically center logo and text
            gap: "8px", // Add space between logo and text
          }}
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
            alt="Google Logo"
            style={{
              width: "20px",
              height: "20px",
              display: "block", // Ensure the logo does not have extra space
            }}
          />
          {Login_text.google_btn}
        </span>
      </div>

      <p className="login__error">{error}</p>

      <div className="terms">
        <span>
          <Link target="blank" to={"/terms"}>
            {terms.terms}
          </Link>
        </span>
        <span>
          <Link target="blank" to={"/packages&Combos"}>
            {terms.Plans}
          </Link>
        </span>
        <span>
          <Link target="blank" to={"/about-educify"}>
            {terms.ContactUs}
          </Link>
        </span>
      </div>
    </div>
  );
};
